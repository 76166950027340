import { SystemActor } from './SystemActor'
import { SystemActorType, UserRole } from './types'

const GAME_CLIENT = Object.freeze(
  new SystemActor( SystemActorType.GameClient, [
    UserRole.ClientConfigRead,
    UserRole.ClientConfigWrite,
    UserRole.ClientInventoryRead,
    UserRole.ClientNameRead,
    UserRole.ClientNameWrite,
    UserRole.OnlineParametersRead,
    UserRole.ClientSquadRead,
    UserRole.ClientSquadWrite,
    UserRole.ClientAndInternalSquadWrite,
    UserRole.ClientFriendsRead,
    UserRole.ClientFriendsWrite,
    UserRole.ClientInvitationsRead,
    UserRole.ClientInvitationsWrite,
    UserRole.ClientStatusWrite,
    UserRole.ClientShopOfferRead,
    UserRole.ClientShopTransaction,
    UserRole.ClientChallengesRead,
    UserRole.AdminAndClientInventoryWrite,
    UserRole.ClientProgressionRead,
    UserRole.InternalAndClientAndServerProgressionRead,
    UserRole.ClientBoostersRead,
    UserRole.ClientBoostersWrite,
    UserRole.ClientXmppConfigRead,
    UserRole.ClientLogWrite,
    UserRole.ClientMetricsWrite,
    UserRole.ClientFindLobby,
    UserRole.ClientJoinLobby,
    UserRole.ClientRejoin,
    UserRole.ClientCancelMatchmaking,
    UserRole.ClientMatchmakingCheats,
    UserRole.InternalAndClientRemovePlayerFromLobby,
    UserRole.ClientAndInternalLobbyWrite,
    UserRole.ClientPlayerDataRead,
    UserRole.StatisticsRead,
    UserRole.ClientRenewToken,
    UserRole.ClientMapsRead,
    UserRole.ClientServersRead,
    UserRole.InternalAndClientServersRead,
    UserRole.ClientLobbyStateRead,
    UserRole.ClientProfileRead,
    UserRole.ClientProfileWrite,
    UserRole.ClientAndServerProfileRead,
    UserRole.ClientPublicPlayerDataRead,
    UserRole.ClientWebsocketUrlRead,
    UserRole.ClientSeasonRead,
    UserRole.ClientSeasonWrite,
    UserRole.ClientGameSessionRead,
    UserRole.ClientNotificationsRead,
    UserRole.ClientNotificationsWrite,
    UserRole.AdminNotificationsWrite,
    UserRole.AdminSendReloadCacheEvent,
    UserRole.ClientServersGroupAndSpawnersIpsRead,
    UserRole.InternalAndExternalShopWrite,
  ] ),
)
const GAME_SERVER = Object.freeze(
  new SystemActor( SystemActorType.GameServer, [
    UserRole.InternalCommunication,
    UserRole.InternalAndServerRemovePlayerFromLobby,
    UserRole.ServerPlayerStateRead,
    UserRole.ServerMetricsWrite,
    UserRole.InternalCommunication,
    UserRole.ServerMetricsWrite,
    UserRole.InternalAndServerStatisticsWrite,
    UserRole.StatisticsRead,
    UserRole.InternalAndServerProgressionRead,
    UserRole.InternalAndClientAndServerProgressionRead,
    UserRole.ServerScoresRead,
    UserRole.ServerRegisterServer,
    UserRole.ServerUpdateServer,
    UserRole.ServerUnregisterServer,
    UserRole.ServerGenerateId,
    UserRole.ClientAndServerProfileRead,
    UserRole.ServerWebsocketUrlRead,
    UserRole.InternalAndServerHandlePlayerLogout,
    UserRole.InternalAndServerHandleRpcRequest,
    UserRole.ServerUnbindEvents,
    UserRole.ServerBindEvents,
    UserRole.ServerSubscribePlayerEvents,
    UserRole.ServerUnsubscribePlayerEvents,
    UserRole.ServerGameParametersRead,
  ] ),
)

const LOG_VIEWER_APP = Object.freeze(
  new SystemActor( SystemActorType.LogViewerApp, [ UserRole.DebugLogRead ] ),
)

const ROUTING_EVENTS_APP = Object.freeze(
  new SystemActor( SystemActorType.RoutingEventsApp, [
    UserRole.InternalCommunication,
    UserRole.ClientAndInternalSquadWrite,
    UserRole.InternalSupportedRevisionWrite,
    UserRole.InternalKickFromLobby,
    UserRole.InternalAndServerRemovePlayerFromLobby,
    UserRole.InternalAndClientRemovePlayerFromLobby,
    UserRole.ClientAndInternalLobbyWrite,
    UserRole.InternalLobbyWrite,
    UserRole.InternalAndServerStatisticsWrite,
    UserRole.StatisticsRead,
    UserRole.InternalAndServerHandleRpcRequest,
    UserRole.InternalAndServerHandlePlayerLogout,
    UserRole.InternalSendToDedicatedServer,
    UserRole.InternalMatchmakingCommunication,
    UserRole.InternalFindLobbyCommunication,
    UserRole.InternalMatchmakingDataProviderCommunication,
    UserRole.InternalTokenRegisterWrite,
    UserRole.ClientPlayersDataRead,
    UserRole.InternalFriendsWrite,
    UserRole.InternalAndExternalShopWrite,
  ] ),
)
const REMOTE_FIND_LOBBY_APP = Object.freeze(
  new SystemActor( SystemActorType.RemoteFindLobbyApp, [
    UserRole.InternalCommunication,
    UserRole.InternalAndClientRemovePlayerFromLobby,
    UserRole.ClientAndInternalLobbyWrite,
    UserRole.InternalLobbyWrite,
  ] ),
)
const REMOTE_MATCHMAKING_APP = Object.freeze(
  new SystemActor( SystemActorType.RemoteMatchmakingApp, [
    UserRole.InternalCommunication,
    UserRole.ClientAndInternalLobbyWrite,
    UserRole.InternalLobbyWrite,
    UserRole.InternalServerKill,
    UserRole.InternalServerSpawn,
  ] ),
)
const MATCHMAKING_APP = Object.freeze(
  new SystemActor( SystemActorType.MatchmakingApp, [
    UserRole.InternalCommunication,
    UserRole.ClientAndInternalLobbyWrite,
    UserRole.InternalLobbyWrite,
    UserRole.InternalServerKill,
    UserRole.InternalServerSpawn,
    UserRole.InternalSendToDedicatedServer,
  ] ),
)
const SPAWNER_MANAGER_APP = Object.freeze(
  new SystemActor( SystemActorType.SpawnerManagerApp, [
    UserRole.InternalCommunication,
    UserRole.InternalSupportedRevisionRead,
    UserRole.InternalCookRevisionRead,
    UserRole.InternalServerKill,
    UserRole.InternalServerSpawn,
    UserRole.InternalSpawnerConfigRead,
    UserRole.InternalSpawnerConfigWrite,
    UserRole.InternalServersGroupAndSpawnersIpsRead,
    UserRole.UpdateSpawnerState,
  ] ),
)
// TODO expand API_SERVER roles
const API_SERVER = Object.freeze(
  new SystemActor( SystemActorType.ApiServer, [
    UserRole.AdminNameRead,
    UserRole.AdminNameWrite,
    UserRole.InternalShopWrite,
  ] ),
)

const API = Object.freeze(
  new SystemActor( SystemActorType.Api, [
    UserRole.AdminNameRead,
    UserRole.AdminNameWrite,
    UserRole.AdminInventoryRead,
    UserRole.AdminInventoryWrite,
    UserRole.AdminAndClientInventoryWrite,
    UserRole.AdminKick,
    UserRole.AdminMute,
    UserRole.AdminBan,
    UserRole.AdminKickAll,
    UserRole.AdminAccountRead,
    UserRole.AdminAccountWrite,
    UserRole.AdminShopTransaction,
    UserRole.AdminAccountDelete,
    UserRole.AdminAndBenchmarkAccountDelete,
    UserRole.AdminMetricsRead,
    UserRole.AdminSpawnerConfiguration,
    UserRole.InternalAndClientAndServerProgressionRead,
    UserRole.InternalAndServerProgressionRead,
    UserRole.InternalAndClientProgressionRead,
    UserRole.InternalAndClientServersRead,
    UserRole.InternalServersRead,
    UserRole.AdminLobbyWrite,
    UserRole.AdminXmppAccountDelete,
    UserRole.AdminGetMaintenanceStatus,
    UserRole.AdminNotificationsWrite,
    UserRole.AdminSendReloadCacheEvent,
    UserRole.InternalAndExternalShopWrite,
  ] ),
)

const BENCHMARK = Object.freeze(
  new SystemActor( SystemActorType.Benchmark, [
    UserRole.BenchmarkAccountRead,
    UserRole.AdminAndBenchmarkAccountDelete,
  ] ),
)

const DEV_TOOLS = Object.freeze(
  new SystemActor( SystemActorType.DevTools, [
    UserRole.DevToolsDatabaseRead,
    UserRole.DevToolsDatabaseWrite,
    UserRole.DevToolsPerformanceProfiling,
    UserRole.DevToolsUpdateMasterServer,
    UserRole.DevToolsLogsRead,
    UserRole.DebugLogRead,
  ] ),
)

const UPTIME_MONITOR = Object.freeze(
  new SystemActor( SystemActorType.UptimeMonitor, [
    UserRole.UptimeGet,
  ] ),
)

const VAULT_CLIENT = Object.freeze(
  new SystemActor( SystemActorType.VaultClient, [
    UserRole.SpawnersConfigRead,
    UserRole.SpawnersConfigWrite,
    UserRole.SpawnersManagement,
    UserRole.VaultBackup,
    UserRole.LobbiesConfigRead,
    UserRole.CookPathsRead,
    UserRole.RegionsRead,
    UserRole.SpawnerManagerConfigWrite,
    UserRole.VaultWhitelistRead,
    UserRole.VaultWhitelistWrite,
    UserRole.UpdateSpawnerState,
    UserRole.VaultDynamicDataRead,
    UserRole.QuickPlayFactorsWrite,
    UserRole.QuickPlayFactorsRead,
  ] ),
)

const VAULT = Object.freeze(
  new SystemActor( SystemActorType.Vault, [
      UserRole.DataProviderUpdateData,
      UserRole.SpawnersManagement,
      UserRole.UpdateSpawnerState,
      UserRole.QuickPlayFactorsWrite,
      UserRole.QuickPlayFactorsRead,
    ],
  ) )

const DATA_PROVIDER = Object.freeze(
  new SystemActor( SystemActorType.DataProvider, [
    UserRole.SpawnersConfigRead,
    UserRole.DataProviderRegister,
    UserRole.LobbiesConfigRead,
    UserRole.CookPathsRead,
    UserRole.RegionsRead,
    UserRole.VaultWhitelistRead,
    UserRole.VaultDynamicDataRead,
    UserRole.QuickPlayFactorsRead,
  ] ),
)

const DATA_MANAGEMENT_APP = Object.freeze(
  new SystemActor( SystemActorType.DataManagementApp, [] ) )
const DATA_MANAGEMENT_APP_UI = Object.freeze(
  new SystemActor( SystemActorType.DataManagementApp, [] ) )

export const SYSTEM_ACTORS = Object.freeze( {
  GAME_CLIENT,
  GAME_SERVER,
  LOG_VIEWER_APP,
  ROUTING_EVENTS_APP,
  REMOTE_FIND_LOBBY_APP,
  REMOTE_MATCHMAKING_APP,
  MATCHMAKING_APP,
  SPAWNER_MANAGER_APP,
  API_SERVER,
  API,
  BENCHMARK,
  DEV_TOOLS,
  UPTIME_MONITOR,
  VAULT_CLIENT,
  VAULT,
  DATA_PROVIDER,
  DATA_MANAGEMENT_APP,
  DATA_MANAGEMENT_APP_UI,
} )
