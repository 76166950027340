import { call } from "../fn/call"

/**
 *
 * @param p
 * @param delayInMilliseconds
 */
export function delay<T>( delayInMilliseconds: number, p: Promise<T>  ) {
  p.catch( () => {} ) // prevent unhandled exception
  return new Promise<T>( ( resolve ) => {
    setTimeout( call, delayInMilliseconds, resolve, p )
  } )
}