import { SystemUser, UserRole } from '../'
import { logger } from '../../logger'
import { PermissionDenied } from '../exceptions/PermissionDenied'

/**
 * Checks if user has required permissions for action.
 * @param {any} api
 * @param {any} context
 * @param {string} method
 * @param {SystemUser} user
 * @returns {void}
 */
export function checkPermission (
  api: any,
  context: string,
  method: string,
  user?: SystemUser,
): void {
  let requiredRoles: UserRole[]
  if ( !user ) {
    const error = new Error( `Missing user in rpc request for ${ context }.${ method }` )
    logger.error( error )
    throw error
  }

  if ( api.target ) {
    requiredRoles = Reflect.getMetadata( 'roles', api.target.__proto__, method )
  } else {
    requiredRoles = Reflect.getMetadata( 'roles', api, method )
  }

  if ( !requiredRoles ) {
    const error = new Error( `Missing roles guard for ${ context }.${ method }` )
    logger.error( error )
    throw error
  }

  if (
    !requiredRoles.every( ( role: UserRole ) => user?.actor?.roles?.includes( role ) )
  ) {
    throw new PermissionDenied()
  }
}
