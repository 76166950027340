import { SystemUser, UserRole } from '../'
import { PermissionDenied } from '../exceptions/PermissionDenied'

/**
 * Wraps api calls and checks permissions.
 * @param {SimpleObject} context - Function context.
 * @param {string} fnName - Name of the function to call.
 * @param {SystemUser} user - User type from token.
 */
export async function safeCall<
  FnName extends string,
  T extends { [k in FnName]: (...args: any) => any }
>(
  context: T,
  fnName: FnName,
  user: SystemUser,
  args: any[]
) {
  const requiredRoles = Reflect.getMetadata(
    'roles',
    context,
    fnName
  ) as UserRole[]

  if (!requiredRoles) {
    throw new Error(
      `Missing roles guard for ${context.constructor.name}.${fnName}`
    )
    
  }

  if (requiredRoles.every((role) => user?.roles?.includes(role))) {
    return await context[fnName](...args)
  }

  throw new PermissionDenied()
}
