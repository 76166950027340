import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "75%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export const EditEnvModal = ({
  open,
  handleClose,
  spawnerManager,
  spawnersName,
  spawnersInUse,
  update,
}: {
  open: boolean;
  spawnerManager: any;
  spawnersName: string[];
  spawnersInUse: string[];
  handleClose: () => void;
  update: (spwner: any) => Promise<void>;
}) => {
  if (!spawnerManager) {
    spawnerManager = {};
  }

  const config = spawnerManager.config ? spawnerManager.config : {};

  const availableSpawners = spawnersName.filter(
    (x) => !spawnersInUse.includes(x)
  );

  if (spawnerManager.spawners) {
    availableSpawners.push(...spawnerManager.spawners);
  }
  //   const currentlySelectedSpawners = spawnerManager.spawners || [];

  const [selectedSpawners, setSelectedSpawners] = React.useState([
    ...(spawnerManager.spawners || []),
  ]);

  const environment = React.useRef(null);
  const spawners = React.useRef(null);

  const masterServerUrlRef = React.useRef(null);
  const masterServerPortRef = React.useRef(null);
  const nodeNameRef = React.useRef(null);
  const hostnameRef = React.useRef(null);
  const portRef = React.useRef(null);
  const inspectPortRef = React.useRef(null);
  const revisionPollingRef = React.useRef(null);

  const handleChange = (event: SelectChangeEvent<typeof selectedSpawners>) => {
    const {
      target: { value },
    } = event;
    setSelectedSpawners(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {spawnerManager.environment || "Add new env"}
        </Typography>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
            // display: "flex",
            // flexDirection: "column",
          }}
          //   noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Environment"
            defaultValue={spawnerManager.environment || null}
            disabled={spawnerManager.environment && true}
            variant="outlined"
            inputRef={environment}
          />
          <Select
            label="Spawners"
            multiple
            renderValue={(selected) => selected.join(", ")}
            defaultValue={spawnerManager.spawners || []}
            onChange={handleChange}
            inputRef={spawners}
          >
            {availableSpawners.map((name) => (
              <MenuItem value={name}>
                <Checkbox checked={selectedSpawners.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
          <Box>
            <TextField
              id="outlined-basic"
              label="MasterServerUrl"
              defaultValue={config.masterServerUrl || null}
              variant="outlined"
              inputRef={masterServerUrlRef}
            />
            <TextField
              id="outlined-basic"
              label="MasterServerPort"
              defaultValue={config.masterServerPort || null}
              variant="outlined"
              inputRef={masterServerPortRef}
            />
            <TextField
              id="outlined-basic"
              label="nodeName"
              defaultValue={config.nodeName || null}
              variant="outlined"
              inputRef={nodeNameRef}
            />
            <TextField
              id="outlined-basic"
              label="hostname"
              defaultValue={config.hostname || null}
              variant="outlined"
              inputRef={hostnameRef}
            />
            <TextField
              id="outlined-basic"
              label="Port"
              defaultValue={config.port || null}
              variant="outlined"
              inputRef={portRef}
            />
            <TextField
              id="outlined-basic"
              label="InspectPort"
              defaultValue={config.inspectPort || null}
              variant="outlined"
              inputRef={inspectPortRef}
            />
            <TextField
              id="outlined-basic"
              label="RevisionPollingInterval"
              defaultValue={config.revisionPollingInterval || null}
              variant="outlined"
              inputRef={revisionPollingRef}
            />
          </Box>
          <Button
            onClick={() => {
              const newSpawnerConfig = {
                environment: environment.current.value,
                spawners: selectedSpawners,
                config: {
                  masterServerUrl: masterServerUrlRef.current.value,
                  masterServerPort: parseInt(masterServerPortRef.current.value),
                  nodeName: nodeNameRef.current.value,
                  hostname: hostnameRef.current.value,
                  port: parseInt(portRef.current.value),
                  inspectPort: parseInt(inspectPortRef.current.value),
                  revisionPollingInterval: parseInt(
                    revisionPollingRef.current.value
                  ),
                },
              };
              update(newSpawnerConfig);
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
