const concat = ( x, y ) => x.concat( y )

/**
 * Method defines non-enumerable property on object.
 *
 * @param {object} object - The object on which to define the property.
 * @param {string|symbol} name - The name or Symbol of the method to be defined or modified.
 * @param {*} property - The property to be define.
 * @param writable
 * @returns {object} The object that was passed to the function.
 */
function defineHiddenProperty( object, name, property, writable = false ) {
  return Object.defineProperty( object, name, {
    enumerable: false,
    value: property,
    writable,
  } )
}

/**
 * Extends prototype of object by defining non-enumerable properties.
 * @param  {object} object     - Object whose prototype to be extended.
 * @param  {object} properties - Properties to be defined.
 * @returns {object}            The object that was passed to the function.
 */
function extendPrototype( object, properties ) {
  const { prototype } = object
  for ( const [k, v] of Object.entries( properties ) ) {
    if ( prototype[k] == null ) {
      defineHiddenProperty( prototype, k, v )
    }
  }
  return object
}

extendPrototype( Array, {
  mutate( fn ) {
    this.forEach( fn )
    return this
  },

  flatMap( fn ) {
    return this.map( fn ).reduce( concat, [] )
  },

  async asyncMap( f ) {
    return await Promise.all( this.map( f ) )
  },

  async asyncFlatMap( f ) {
    // const maybeArrays = await Promise.all(this.map(f))
    // const result = maybeArrays.reduce(concat, [])
    return ( await Promise.all( this.map( f ) ) ).reduce( concat, [] )
  },

  random() {
    const randomIndex = ( this.length * Math.random() ) | 0
    return this[randomIndex]
  },

  removeRandom() {
    const randomIndex = ( this.length * Math.random() ) | 0
    return this.splice( randomIndex, 1 )[0]
  },

  remove( element ) {
    const index = this.indexOf( element )
    if ( index != -1 ) {
      return this.splice( index, 1 )[0]
    }
  },

  replace( oldElement, newElement ) {
    const index = this.indexOf( oldElement )
    if ( index != -1 ) {
      this[index] = newElement
    }
  },

  subarrayRandom( count ) {
    const countToPick = Math.min( this.length, count )
    const copy = this.slice()
    const result = []

    for ( let i = 0; i < countToPick; ++i ) {
      result.push( copy.removeRandom() )
    }
    return result
  },

  group( mapper ) {
    const result = {}
    for ( let i = 0; i < this.length; ++i ) {
      var element = this[i]
      const mappedIndex = mapper( element )
      const grouped = result[mappedIndex]
        ? result[mappedIndex]
        : ( result[mappedIndex] = [] )
      grouped.push( element )
    }
    return result
  },

  split( splitter ) {
    const a = []
    const b = []
    const result = [a, b]
    for ( let i = 0; i < this.length; ++i ) {
      var element = this[i]
      if ( splitter( element, i ) ) {
        a.push( element )
      } else {
        b.push( element )
      }
    }
    return result
  },

  splitMap( splitter, mapA, mapB = mapA ) {
    const a = []
    const b = []
    const result = [a, b]
    for ( let i = 0; i < this.length; ++i ) {
      var element = this[i]
      if ( splitter( element, i ) ) {
        a.push( mapA( element ) )
      } else {
        b.push( mapB( element ) )
      }
    }
    return result
  },
} )

Array.first = ( [a] ) => a
Array.second = ( [, b] ) => b
Array.last = ( arr ) => arr[arr.length - 1]
Array.range = ( start, stop, step = 1 ) => {
  const result = []
  for ( let i = start; i <= stop; i += step ) {
    result.push( i )
  }
  return result
}

extendPrototype( String, {
  capitalize: require( './capitalize' ),
} )

extendPrototype( Promise, {
  async asyncFlatMap( f ) {
    const array = await this
    return array.flatMap( f )
  },
} )

extendPrototype( Set, {
  addMany( elements ) {
    for ( const e of elements ) {
      this.add( e )
    }
  },
  deleteMany( elements ) {
    for ( const e of elements ) {
      this.delete( e )
    }
  },
} )

extendPrototype( Object, {
  mutate( mutator ) {
    mutator( this )
    return this
  },
} )

Object.redefineValues = function ( object, properties ) {
  for ( const [propertyName, value] of Object.entries( properties ) ) {
    const descriptor = Object.getOwnPropertyDescriptor(
      object,
      propertyName
    ) || { enumerable: true, writable: false, configurable: true }
    descriptor.value = value
    Object.defineProperty( object, propertyName, descriptor )
  }
}

Object.copy = function ( object ) {
  return Object.assign( {}, object )
}

Object.equal = require( 'fast-deep-equal' )

Object.deepCopy = function ( object ) {
  var copy
  if ( Array.isArray( object ) ) {
    copy = [...object]
    for ( let i = 0; i < copy.length; ++i ) {
      copy[i] = Object.deepCopy( copy[i] )
    }
    return copy
  } else if ( object instanceof Object ) {
    copy = Object.assign( {}, object )
    for ( const [i, value] of Object.entries( object ) ) {
      copy[i] = Object.deepCopy( value )
    }
    return copy
  } else {
    return object
  }
}

Object.flatten = function ( object ) {
  const result = {}
  for ( const k in object ) {
    result[k] = object[k]
  }
  return result
}

Object.findPropertyWithValue = function ( object, value ) {
  for ( const [k, v] of Object.entries( object ) ) {
    if ( v === value ) {
      return k
    }
  }
}

Object.filter = function ( object, fn ) {
  const result = {}
  for ( const [k, v] of Object.entries( object ) ) {
    if ( fn( v, k, object ) ) {
      result[k] = v
    }
  }
  return result
}
;( Object.fromEntries = function ( entries ) {
  const result = {}

  for ( const [k, v] of entries ) {
    result[k] = v
  }
  return result
} ),
  ( Object.map = function ( object, fn ) {
    const result = {}
    for ( const [k, v] of Object.entries( object ) ) {
      result[k] = fn( v, k, object )
    }
    return result
  } )

Object.asyncMap = async function ( object, fn ) {
  const entries = await Object.entries( object ).asyncMap( async ( [k, v] ) => [
    k,
    await fn( v, k, object ),
  ] )
  return Object.fromEntries( entries )
}

Object.defineHiddenGetters = function ( object, properties ) {
  for ( const [key, value] of Object.entries( properties ) ) {
    Object.defineProperty( object, key, {
      get: value,
      configurable: true,
      enumerable: false,
    } )
  }
  return object
}

Object.defineHiddenProperty = defineHiddenProperty

const { isArray } = Array

/**
 * @param array
 */
function copyArrayDeep( array ) {
  const result = new Array( array.length )
  for ( let i = 0; i < array.length; ++i ) {
    result[i] = getOwnProperties( array[i] )
  }
  return result
}

/**
 * @param object
 */
function copyObjectDeep( object ) {
  const result = {}
  for ( const k in object ) {
    if ( object.hasOwnProperty( k ) && k !== '_id' ) {
      result[k] = getOwnProperties( object[k] )
    }
  }
  return result
}

const getOwnProperties = ( Object.getOwnProperties = function ( object ) {
  if ( 'object' == typeof object ) {
    if ( !isArray( object ) ) {
      if ( !( object instanceof Date ) ) {
        return copyObjectDeep( object )
      } else {
        return object
      }
    } else {
      return copyArrayDeep( object )
      // const result = new Array(object.length)
      // for (let i = 0; i < object.length; ++i) {
      //   result[i] = copyArrayDeep(object[i])
      // }
      // return result
    }
  } else {
    return object
  }
} )
