import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { QuickPlayFactors } from 'shared'

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "75%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export const EditFactorsModal = ({
                               open,
                               handleClose,
                               factors,
                               update,
                             }: {
  open: boolean;
  factors: {env: string, data: QuickPlayFactors};
  handleClose: () => void;
  update: (data: {environment: string, factors: QuickPlayFactors}) => Promise<void>;
}) => {
  if (!factors) {
    factors = {
      env: undefined,
      data: {} as QuickPlayFactors
    } as {env: string, data: QuickPlayFactors};
  }

  const environment = React.useRef(null);
  const activeRef = React.useRef(null);
  const gameModesRef = React.useRef(null);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {factors.env || "Add new factors"}
        </Typography>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
            // display: "flex",
            // flexDirection: "column",
          }}
          //   noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Environment"
            defaultValue={factors.env || null}
            disabled={factors.env && true}
            variant="outlined"
            inputRef={environment}
          />
          <Box>
            <Checkbox defaultChecked={factors.data?.isActive ?? false} inputRef={activeRef}/> IsActive
            <TextField
              id="outlined-basic"
              label="MasterServerPort"
              defaultValue={factors.data?.gameModes.map(gm => (`${gm.value}: ${gm.weight},`)) || null}
              variant="outlined"
              inputRef={gameModesRef}
            />
          </Box>
          <Button
            onClick={() => {
              const newFactors = {} as QuickPlayFactors
              newFactors.isActive = activeRef.current.checked

              const gameModesString = gameModesRef.current.value as string

              const withoutWhitespaces = gameModesString.replace(/\s/g, '')
              const gameModes = withoutWhitespaces.split(',').reduce((acc, gm) => {
                const d = gm.split(':')
                acc.push({
                  value: d[0],
                  weight: parseFloat(d[1])
                })
                return acc
              }, [])
              newFactors.gameModes = gameModes

              update({environment: factors.env, factors: newFactors});
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
