import { Constructor } from "type-fest"

type iClientErrorsConstructor = Constructor<Error>

export function clientError (): <T extends iClientErrorsConstructor>(
  constructor: T,
) => T {
  let code: string

  return function <T extends iClientErrorsConstructor> ( constructor: T ): T {
    // const errorCodesByError = errorCodes.errorCodesByError
    // const name = constructor.name
    // if ( errorCodesByError[name] ) {
    //   code = errorCodesByError[name]
    // } else {
    //   code = `${ +errorCodes.lastId + 1 }`
    //   errorCodes.updateErrorCodes( code, name )
    // }

    return class extends constructor {
      readonly messageCode = code
    }
  }
}