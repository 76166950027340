import { SystemActor } from './SystemActor'

export class SystemUser {
  id: string

  actor: SystemActor

  constructor( id: string, actor: SystemActor ) {
    this.id = id
    this.actor = actor
  }

  get roles() {
    return this.actor.roles
  }
}