export interface iIdGenerator {
    next(): number
  }
  

export class LocalIdGenerator implements iIdGenerator {
  private nextId: number

  private limit: number

  constructor( limit = 2 ** 32 - 1 ) {
    this.nextId = 0
    this.limit = limit
  }

  next(): number {
    const { nextId, limit } = this
    this.nextId = ( nextId + 1 ) % limit
    return nextId
  }
}