import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import Accordion from '@mui/material/Accordion'
import { QuickPlayFactors } from 'shared'

export const FactorsCard = ({
  factors,
  edit,
  env
}: {
  factors: QuickPlayFactors,
  env: string
  edit: (env) => void
}) => {
  return (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      QuickPlayFactors
    </AccordionSummary>
    <AccordionDetails>
      isActive: {factors?.isActive ? "true" : "false"} <br />
      gameModes: {factors?.gameModes.map(gm => (<>{gm.value}: {gm.weight}<br /></>))}
      <BottomNavigation showLabels style={{ width: "100%" }}>
        <BottomNavigationAction
          label="Edit"
          icon={<EditIcon />}
          onClick={() => edit(env)}
        />
      </BottomNavigation>
    </AccordionDetails>
  </Accordion>
  )
}