import { ObjectSocket, WrapperWebSocketBase } from "./ObjectSocket";
import WS from "ws";

export class WrapperWS implements WrapperWebSocketBase {
  private readonly socket: WebSocket;

  private readonly objectSocket: ObjectSocket;

  constructor(socket: WebSocket, objectSocket: ObjectSocket) {
    this.socket = socket;
    this.objectSocket = objectSocket;

    this.socket.onmessage = (event) => {
      this.objectSocket.handleMessage({
        data: event.data,
        target: this.objectSocket,
      });
    };
    this.socket.onopen = () =>
      this.objectSocket.emit("open", { target: this.objectSocket });
    this.socket.onclose = () =>
      this.objectSocket.emit("close", { target: this.objectSocket });
    this.socket.onerror = (error) => {
      console.log(`[WSError] ${error}`);
    };
  }

  isOpen(): boolean {
    return this.socket.readyState === WS.OPEN;
  }

  isClosed(): boolean {
    return this.socket.readyState === WS.CLOSED;
  }

  close() {
    this.socket.close();
  }

  send(data: string) {
    this.socket.send(data);
  }

  // terminate() {
  //     this.socket.terminate()
  // }
  get url(): string {
    return this.socket.url;
  }
}
