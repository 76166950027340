import { Timeout } from "../exceptions";
import { TimestampInMilliseconds } from "../time";

/**
 * Rejects promise after specified time.
 * @param ms Time to wait before promise is rejected.
 * @returns 
 */
export function timeout( ms: TimestampInMilliseconds, message?: string ) {
  return new Promise<never> ((_resolve, reject)  => {
    setTimeout( reject, ms, new Timeout( message) )
  })
}