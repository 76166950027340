import { AnyObject, WithType } from "../utils/types";
import { EventEmitter } from "events";
import { WrapperWS } from "./WrapperWS";
import { source } from "./types";

export interface Message extends WithType {
  toJSON?(): AnyObject;
  [K: string]: any;
  [K: number]: any;
}

// export type IncommingMessage = WithType & {
//   [source]: ObjectSocket;
//   toJSON?(): JsonObject;
// };

export type ObjectSocketMessageEvent = {
  data: string | Buffer | ArrayBuffer | Buffer[];
  target: ObjectSocket;
};

export interface WrapperWebSocketBase {
  isOpen(): boolean;
  isClosed(): boolean;
  send(data: string): void;
  close(): void;
  get url(): string;
}

const defaultOptions = {
  WebSocket: global.WebSocket,
  rejectUnauthorized: false,
  perMessageDeflate: false,
};

export class ObjectSocket extends EventEmitter {
  type: "ObjectSocket";

  private readonly socketWrapper: WrapperWebSocketBase;

  opened: Promise<ObjectSocket>;

  closed: Promise<void>;

  constructor(urlOrSocket: string | WebSocket, options = {}) {
    super();
    this.type = "ObjectSocket";
    if (typeof urlOrSocket === "string") {
      const socket = new WebSocket(urlOrSocket, ["some-secret-token"]);
      this.socketWrapper = new WrapperWS(socket, this);
    } else {
      this.socketWrapper = new WrapperWS(urlOrSocket, this);
    }

    this.opened = new Promise<ObjectSocket>((resolve, reject) => {
      if (this.socketWrapper.isOpen()) {
        this.initClose();
        return resolve(this);
      }
      const timer = setTimeout(() => {
        reject(Error("Timeout"));
      }, 20000);
      this.on("open", () => {
        this.initClose();
        clearTimeout(timer);
        resolve(this);
      });
    });

    this.redirect = this.redirect.bind(this);
    this.send = this.send.bind(this);
  }

  send(data: Message): void {
    try {
      const dataToJson =
        typeof data.toJSON === "function" ? data.toJSON() : data;
      const jsonStringify = JSON.stringify(dataToJson);

      this.socketWrapper.send(jsonStringify);
    } catch (e) {
      console.error(e);
    }
  }

  handleMessage(evt: ObjectSocketMessageEvent) {
    try {
      if (typeof evt.data === "string") {
        const data: unknown = JSON.parse(evt.data);
        Object.defineProperty(data, "source", {
          value: this,
          enumerable: false,
        });
        (data as any)[source] = this;
        this.emit("message", data);
      } else {
        throw new Error(`Unsupported data type ${typeof evt.data}`);
      }
    } catch (e) {
      console.log(`Failed to handle socket message, reason: ${e}`);
    }
  }

  hasValidConnection(): boolean {
    return this.socketWrapper.isClosed();
  }

  close(): void {
    this.socketWrapper.close();
  }

  redirect(event: unknown): void {
    this.send({ type: "redirect", event });
  }

  initClose() {
    this.closed = new Promise((resolve) => {
      if (this.socketWrapper.isClosed()) {
        resolve();
      }
      this.on("close", () => {
        resolve();
      });
    });
  }
}
