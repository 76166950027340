/**
 * @param array
 * @param value
 */
export function removeOne<T>( array: T[], value: T ): boolean {
  const indexOf = array.indexOf( value )
  if ( indexOf !== -1 ) {
    array.splice( indexOf, 1 )
    return true
  }
  return false
}
