import { ClientException } from "./ClientException"
import { ClientMessage } from "./ClientMessage"
import { LocalError } from "./LocalError"
import { clientError } from "./decorators"

export interface ClientError extends LocalError {
  messageCode: string
  clientMessage: ClientMessage
}

@clientError() // check if this is safe to use outside ww3-master
export class ErrorWithClientData extends Error implements ClientException {
  readonly clientMessage = 'Something went wrong'
  readonly messageCode: string // decorator sets it

  constructor( message: string ) {
    super( message )
  }
}