export type LogTag = string & { __LogTag__: void }

export type Tag = {
  id: LogTag
  description: string
}

export const tag = ( id: string, description: string ) => ( {
  id: id as LogTag,
  description,
} )


export const tags = {
  Challenges: tag( 'ch', 'Challenges related logs' ),
  Monetization: tag( 'mn', 'Monetization related logs' ),
  Mismatched: tag( 'mi', 'Mismatched data/setup/revision/version etc.' ),
  MatchMaking: tag( 'mm', 'MatchMaking related logs' ),
  Lobby: tag( 'lb', 'Lobby related logs' ),
  Progression: tag( 'prg', 'Progression related logs' ),
  Reward: tag( 'rwd', 'Reward related logs' ),
  Security: tag( 'sec', 'Security related logs' ),
  SpawnerManager: tag( 'sm', 'SpawnerManager related logs' ),
  Rpc: tag( 'rpc', 'Rpc related logs' ),
  Resource: tag( 'rs', 'Resource related logs' ),
  Vault: tag( 'vt', 'Vault related logs' ),
  Spawner: tag( 'spwn', 'Spawner related logs' ),
} as const
  