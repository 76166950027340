import { SpawnerConfig } from "@ww3-backend/ww3-master/lib/config";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { Options } from "./Options/Options";
import { useEffect, useState } from "react";

export const Spawner = ({
  state,
  spawner,
  runningState,
  menuFunctions,
}: {
  spawner: SpawnerConfig;
  state: { inVault: string; spawnerState: string; inSpawnerManager: string };
  runningState: boolean;
  menuFunctions: {
    start: (spawnerName: string) => Promise<void>;
    stop: (spawnerName: string) => Promise<void>;
    restart: (spawnerName: string) => Promise<void>;
    delete: (spawnerName: string, version: string) => Promise<void>;
    setLock: (
      locked: boolean,
      spawnerName: string,
      spawnerVersion: string
    ) => Promise<void>;
    edit: () => void;
  };
}) => {
  const [spawnerState, setSpawnerState] = useState("unknown");

  useEffect(() => {
    const states = Object.values(state);

    if (states.some((s) => s === "unknown")) {
      setSpawnerState("unknown");
    } else {
      setSpawnerState(state.spawnerState);
    }
  }, [state]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item>
            <Chip
              label={runningState ? "Online" : "Offline"}
              color={runningState ? "success" : "error"}
            />
          </Grid>
          <Grid item>
            {runningState ? (
              <Chip
                label={spawnerState}
                color={
                  spawnerState === "locked"
                    ? "error"
                    : spawnerState === "unknown"
                    ? "warning"
                    : "success"
                }
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <Typography>{spawner.name}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        IP: {spawner.ip} <br />
        Port: {spawner.port} <br />
        Size: {spawner.size} <br />
        Region: {spawner.region} <br />
        GameModes: {JSON.stringify(spawner.supportedGameModes)} <br />
        gamePorts: {JSON.stringify(spawner.gamePorts)} <br />
        queryPorts: {JSON.stringify(spawner.queryPorts)} <br />
        Config version: {spawner.version} <br />
        <Options
          isLocked={spawnerState === "locked"}
          version={spawner.version}
          spawnerName={spawner.name}
          menuFunctions={menuFunctions}
          online={runningState}
        />
      </AccordionDetails>
    </Accordion>
  );
};
