import { createContext, useContext, useEffect, useState } from "react";
import { RpcClient } from "../net/rpc/RpcClient";
import axios from "axios";
import { ObjectSocket } from "../net/ObjectSocket";
import { SystemUser, SYSTEM_ACTORS } from "shared/lib/security";

const RpcContext = createContext<{
  authenticated: boolean;
  socket: ObjectSocket;
  call: (context: string, method: string, args: any[]) => Promise<any>;
  authenticate: (user: string, passwd: string) => Promise<void>;
  rpcClient: RpcClient;
  message: any;
  ready: boolean;
}>(null as any);

const vaultUrl = "";

console.log(vaultUrl);
console.log(`${vaultUrl}/authenticate`);

export const RpcProvider = ({ children }: { children: any }) => {
  const [rpc, setRpc] = useState<any>();
  const [socket, setSocket] = useState<ObjectSocket>();
  const [rpcReady, setRpcReady] = useState(false);
  const [msg, setMessage] = useState(null) as any;
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const ticket = sessionStorage.getItem("ticket");

    if (ticket) {
      connectWS(ticket).then(() => {
        setAuthenticated(true);
      });
    } else {
      setAuthenticated(false);
    }
  }, []);

  const authenticate = async (user: string, passwd: string) => {
    try {
      const ticket = (
        await axios.post(`${vaultUrl}/authenticate`, { user, passwd })
      ).data;
      sessionStorage.setItem("ticket", ticket);
      setAuthenticated(true);
      connectWS(ticket);
    } catch (e) {
      console.log(e);
    }
  };

  const connectWS = async (ticket: string) => {
    console.log({ ticket });
    const wsUrl = (
      await axios.get(`${vaultUrl}/ws/getUIWebsocketUrl`, {
        headers: {
          ticket,
        },
      })
    ).data;
    const rpc = await connectRpcClient(wsUrl);
    await rpc.rpcClient.ready;
    setRpc(rpc.rpcClient);
    setRpcReady(true);
  };

  const rpcCall = async (context: string, method: string, args: any[]) => {
    if (rpc) {
      await rpc.ready;

      try {
        const rpcResponse = await rpc.call(context, method, args);

        console.log({
          RpcRequest: { context, method, args },
          RpcResponse: rpcResponse,
        });

        return rpcResponse;
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  };

  const connectRpcClient = async (wsUrl: string) => {
    console.log(wsUrl);
    const socket = new ObjectSocket(wsUrl);
    await socket.opened;

    socket.on("message", (message) => {
      if (message.type !== "RpcResponse") {
        console.log({ ...message });
      }

      if (message.type === "clientMessage") {
        setMessage(message);
      }
    });

    setSocket(socket);
    const rpcClient = new RpcClient(
      "client",
      socket,
      new SystemUser("vault-ui", SYSTEM_ACTORS.VAULT_CLIENT)
    );
    await rpcClient.ready;
    setRpc({ ...rpcClient });
    return { rpcClient, socket };
  };

  return (
    <RpcContext.Provider
      value={{
        rpcClient: rpc,
        socket,
        call: rpcCall,
        message: msg,
        ready: rpcReady,
        authenticated,
        authenticate,
      }}
    >
      {children}
    </RpcContext.Provider>
  );
};

export const useRpc = () => {
  return useContext(RpcContext);
};
