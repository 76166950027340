import { iRpcClient } from './types'
import { Deferred } from 'shared/lib/promise'
import { LocalIdGenerator } from 'shared/lib/id/LocalIdGenerator'
import { ObjectSocket } from '../ObjectSocket'
import { SystemUser } from 'shared/lib/security'

export class RpcClient implements iRpcClient {
  id: number
  socket: ObjectSocket
  ready: Promise<void>
  requestsById: Record<number, Deferred<any>>
  requestsIds: LocalIdGenerator
  user: SystemUser

  constructor( id: any, socket: ObjectSocket, user: SystemUser ) {
    this.id = id
    this.socket = socket
    this.requestsById = {}
    this.requestsIds = new LocalIdGenerator()
    this.user = user

    socket.on( 'message', ( it ) => {
      if ( it.type === 'RpcResponse' ) {
        const request = this.requestsById[it.id]
        if ( request ) {
          delete this.requestsById[it.id]
          if ( !it.error ) {
            request.resolve( it.result )
          } else {
            request.reject( `${ it.error.message }` )
          }
        }
      }
    } )
  }

  call<T extends unknown = unknown>(
    context: string,
    method: string,
    args: any[],
  ): Deferred<T> {
    const requestId = this.requestsIds.next()
    const request = ( this.requestsById[requestId] = new Deferred<T>() )

    this.socket.send( {
      type: 'RpcRequest',
      id: requestId,
      context,
      method,
      args,
      user: this.user,
    } )

    return request
  }
}
