type ResolveCallback<T = any> = ( r: T ) => void
type ErrorCallback<T = any> = ( e: T ) => void

export class Deferred<T = void, E = any> implements Promise<T> {
  state: string

  private readonly promise: Promise<T>

  private readonly _resolve: ResolveCallback

  private readonly _reject: ErrorCallback

  value?: T

  constructor() {
    let _resolve = ( null as unknown ) as ResolveCallback
    let _reject = ( null as unknown ) as ErrorCallback
    this.promise = new Promise( ( resolve, reject ) => {
      _resolve = resolve
      _reject = reject
    } )

    this._resolve = _resolve
    this._reject = _reject

    this.state = 'pending'
  }

  resolve( v: T ): void {
    this.state = 'resolved'
    this.value = v
    this._resolve( v )
  }

  reject( e: E ): void {
    this.state = 'rejected'
    this._reject( e )
  }

  isPending(): boolean {
    return this.state === 'pending'
  }

  then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ( ( value: T ) => TResult1 | PromiseLike<TResult1> ) | undefined | null,
    onrejected?: ( ( reason: any ) => TResult2 | PromiseLike<TResult2> ) | undefined | null
  ): Promise<TResult1 | TResult2> {
    return this.promise.then( onfulfilled, onrejected )
  }

  catch<TResult = never>( onrejected?: ( reason: any ) => TResult | PromiseLike<TResult> ): Promise<T | TResult> {
    return this.promise.catch( onrejected )
  }

  finally( onfinally?: ( () => void ) | undefined | null ): Promise<T> {
    return this.promise.finally( onfinally )
  }

  get [Symbol.toStringTag](): string {
    return 'Deferred'
  }
}
