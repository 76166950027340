import {
  BottomNavigation,
  BottomNavigationAction,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

export const EnvironmentCard = ({
  environment,
  edit,
  deleteEnv,
  children
}: {
  environment: {
    environment: string;
    spawners: string[];
    config: Record<string, any>;
  };
  edit: (env: any) => void;
  deleteEnv: (env: string) => Promise<void>;
  children?: string | JSX.Element | JSX.Element[]
}) => {
  const { config } = environment;

  return (
    <Card variant="outlined" sx={{width: "100%"}}>
      <CardContent>
        <Typography variant="h5" component="div">
          Environment: {environment.environment}
        </Typography>
        <Typography variant="body2">
          <>
            {environment.environment === 'Unassigned spawners' ? <></> : <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                SpawnerManager config
              </AccordionSummary>
              <AccordionDetails>
                { config ? Object.entries(config).map(([key, value]) => (
                  <span>
              {key}: {value}
                    <br />
            </span>
                )) : <></>}
                <BottomNavigation showLabels style={{ width: "100%" }}>
                  <BottomNavigationAction
                    label="Edit"
                    icon={<EditIcon />}
                    onClick={() => edit(environment)}
                  />
                  <BottomNavigationAction
                    label="Delete"
                    icon={<DeleteIcon />}
                    onClick={() => deleteEnv(environment.environment)}
                  />
                </BottomNavigation>
              </AccordionDetails>
            </Accordion>}
            {children}
          </>
        </Typography>
      </CardContent>
    </Card>
  );
};
