import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { useState } from 'react'

export const Menu = ({menuButtonClick, pageName}: {pageName: string, menuButtonClick: (option: string) => void}) => {
  const [open, setOpen] = useState(false)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <Button onClick={() => toggleDrawer()}>
              <MenuIcon />
            </Button>
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {pageName}
          </Typography>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor='left'
        open={open}
        onClose={() => toggleDrawer()}
      >
        <Button
          onClick={() => {
            menuButtonClick('editSpawner')
          }}
        >
          Add spawner
        </Button>
        <Button
          onClick={() => {
            menuButtonClick('editSpawnerManager');
          }}
        >
          Add spawnerManager
        </Button>
        <Button onClick={() =>menuButtonClick('whitelist')}>Whitelist</Button>
        <Button onClick={() => menuButtonClick('spawners')}>Spawners</Button>
      </Drawer>
    </Box>
  );
};
