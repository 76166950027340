import { SystemUser } from './SystemUser'

export enum UserRole {
  ClientNameRead = 'ClientNameRead',
  ClientNameWrite = 'ClientNameWrite',
  ClientConfigRead = 'ClientConfigRead',
  ClientConfigWrite = 'ClientConfigWrite',
  ClientShopTransaction = 'ClientShopTransaction',
  ClientShopOfferRead = 'ClientShopOfferRead',
  InternalShopWrite = 'InternalShopWrite',
  ClientInventoryRead = 'ClientInventoryRead',
  ClientInventoryWrite = 'ClientInventoryWrite',
  InternalCommunication = 'InternalCommunication',
  OnlineParametersRead = 'OnlineParametersRead',
  AdminNameWrite = 'AdminNameWrite',
  AdminNameRead = 'AdminNameRead',
  ClientSquadRead = 'ClientSquadRead',
  ClientSquadWrite = 'ClientSquadWrite',
  ClientAndInternalSquadWrite = 'ClientAndInternalSquadWrite',
  ClientFriendsRead = 'ClientFriendsRead',
  ClientFriendsWrite = 'ClientFriendsWrite',
  ClientInvitationsRead = 'ClientInvitationsRead',
  ClientInvitationsWrite = 'ClientInvitationsWrite',
  ClientStatusWrite = 'ClientStatusWrite',
  ClientChallengesRead = 'ClientChallengesRead',
  ClientPlayersDataRead = 'ClientPlayersDataRead',
  AdminInventoryRead = 'AdminInventoryRead',
  AdminInventoryWrite = 'AdminInventoryWrite',
  AdminAndClientInventoryWrite = 'AdminAndClientInventoryWrite',
  AdminAccountRead = 'AdminAccountRead',
  AdminAccountWrite = 'AdminAccountWrite',
  AdminBan = 'AdminBan',
  AdminKick = 'AdminKick',
  AdminKickAll = 'AdminKickAll',
  AdminMute = 'AdminMute',
  AdminNotificationsWrite = 'AdminNotificationsWrite',
  AdminSendReloadCacheEvent = 'AdminSendReloadCacheEvent',
  AdminSpawnerConfiguration = 'AdminSpawnerConfiguration',
  AdminAccountDelete = 'AdminAccountDelete',
  AdminShopTransaction = 'AdminShopTransaction',
  AdminXmppAccountDelete = 'AdminXmppAccountDelete',
  BenchmarkAccountRead = 'BenchmarkAccountRead',
  AdminAndBenchmarkAccountDelete = 'AdminAndBenchmarkAccountDelete',
  ClientRejoin = 'ClientRejoin',
  ClientFindLobby = 'ClientFindLobby',
  ClientJoinLobby = 'ClientJoinLobby',
  ClientCancelMatchmaking = 'ClientCancelMatchmaking',
  ClientMatchmakingCheats = 'ClientMatchmakingCheats',
  InternalSupportedRevisionWrite = 'InternalSupportedRevisionWrite',
  InternalKickFromLobby = 'InternalKickFromLobby',
  InternalAndClientRemovePlayerFromLobby = 'InternalAndClientRemovePlayerFromLobby',
  ServerPlayerStateRead = 'ServerPlayerStateRead',
  InternalAndServerRemovePlayerFromLobby = 'InternalAndServerRemovePlayerFromLobby',
  InternalPLayerStatusRead = 'InternalPLayerStatusRead',
  ClientAndInternalLobbyWrite = 'ClientAndInternalLobbyWrite',
  InternalLobbyWrite = 'InternalLobbyWrite',
  ServerMetricsWrite = 'ServerMetricsWrite',
  ClientMetricsWrite = 'ClientMetricsWrite',
  AdminMetricsRead = 'AdminMetricsRead',
  ClientLogWrite = 'ClientLogWrite',
  ClientXmppConfigRead = 'ClientXmppConfigRead',
  ClientBoostersRead = 'ClientBoosterRead',
  ClientBoostersWrite = 'ClientBoosterWrite',
  ClientPlayerDataRead = 'ClientPlayerDataRead',
  ClientRenewToken = 'ClientRenewToken',
  ClientProgressionRead = 'ClientProgressionRead',
  InternalAndClientProgressionRead = 'ClientProgressionRead',
  InternalAndServerProgressionRead = 'ServerProgressionRead',
  InternalAndClientAndServerProgressionRead = 'ClientAndServerProgressionRead',
  InternalAndServerStatisticsWrite = 'InternalAndServerStatisticsWrite',
  StatisticsRead = 'StatisticsRead',
  ClientMapsRead = 'ClientMapsRead',
  ServerScoresRead = 'ServerScoresRead',
  ServerStatisticsWrite = 'ServerStatisticsWrite',
  ClientStatisticsRead = 'ClientStatisticsRead',
  InternalSupportedRevisionRead = 'InternalSupportedRevisionRead',
  InternalCookRevisionRead = 'InternalCookRevisionRead',
  InternalServerSpawn = 'InternalServerSpawn',
  InternalServerKill = 'InternalServerKill',
  ServerRegisterServer = 'ServerRegisterServer',
  ServerUnregisterServer = 'ServerUnregisterServer',
  ServerUpdateServer = 'ServerUpdateServer',
  ServerGenerateId = 'ServerGenerateId',
  ClientServersRead = 'ClientServersRead',
  InternalAndClientServersRead = 'InternalAndClientServersRead',
  ClientLobbyStateRead = 'ClientLobbyStateRead',
  ClientProfileRead = 'ClientProfileRead',
  ClientProfileWrite = 'ClientProfileWrite',
  ClientAndServerProfileRead = 'ClientAndServerProfileRead',
  ClientPublicPlayerDataRead = 'ClientPublicPlayerDataRead',
  AdminLobbyWrite = 'AdminLobbyWrite',
  ServerWebsocketUrlRead = 'ServerWebsocketUrlRead',
  ClientWebsocketUrlRead = 'ClientWebsocketUrlRead',
  InternalAndServerHandlePlayerLogout = 'InternalAndServerHandlePlayerLogout',
  InternalSendToDedicatedServer = 'InternalSendToDedicatedServer',
  InternalAndServerHandleRpcRequest = 'InternalAndServerHandleRpcRequest',
  ServerUnbindEvents = 'ServerUnbindEvents',
  ServerBindEvents = 'ServerBindEvents',
  ServerSubscribePlayerEvents = 'ServerSubscribePlayerEvents',
  ServerUnsubscribePlayerEvents = 'ServerUnsubscribePlayerEvents',
  InternalMatchmakingCommunication = 'InternalMatchmakingCommunication',
  InternalTokenRegisterWrite = 'InternalTokenRegisterWrite',
  InternalFindLobbyCommunication = 'InternalFindLobbyCommunication',
  InternalEventRouterCommunication = 'InternalEventRouterCommunication',
  InternalMatchmakingDataProviderCommunication = 'InternalMatchmakingDataProviderCommunication',
  ClientSeasonRead = 'ClientSeasonRead',
  ClientSeasonWrite = 'ClientSeasonWrite',
  ClientGameSessionRead = 'ClientGameSessionRead',
  DebugLogRead = 'DebugLogRead', //Log viewer uses this role for reading logs
  ServerGameParametersRead = 'ServerGameParametersRead',
  InternalServersRead = 'InternalServersRead',
  InternalSpawnerConfigWrite = 'InternalSpawnerConfigWrite',
  InternalSpawnerConfigRead = 'InternalSpawnerConfigRead',
  AdminGetMaintenanceStatus = 'AdminGetMaintenanceStatus',
  DevToolsDatabaseRead = 'DevToolsDatabaseRead',
  DevToolsDatabaseWrite = 'DevToolsDatabaseWrite',
  DevToolsPerformanceProfiling = 'DevToolsPerformanceProfiling',
  DevToolsUpdateMasterServer = 'DevToolsUpdateMasterServer',
  DevToolsLogsRead = 'DevToolsLogsRead',
  InternalFriendsWrite = 'InternalFriendsWrite',
  ClientNotificationsRead = 'ClientNotificationsRead',
  ClientNotificationsWrite = 'ClientNotificationsWrite',
  UptimeGet = 'UptimeGet',
  SpawnersConfigWrite = 'VaultSpawnersConfigWrite',
  SpawnersConfigRead = 'VaultSpawnersConfigRead',
  SpawnersManagement = 'VaultSpawnersManagement',
  VaultBackup = 'VaultBackup',
  DataProviderRegister = 'DataProviderRegister',
  DataProviderUpdateData = 'DataProviderUpdateData',
  ClientServersGroupAndSpawnersIpsRead = 'ClientServersGroupAndSpawnersIpsRead',
  InternalServersGroupAndSpawnersIpsRead = 'InternalServersGroupAndSpawnersIpsRead',
  LobbiesConfigRead = 'VaultLobbiesConfigRead',
  CookPathsRead = 'VaultCookPathsRead',
  RegionsRead = 'VaultRegionsRead',
  UpdateSpawnerState = 'UpdateSpawnerState',
  SpawnerManagerConfigWrite = 'SpawnerManagerConfigWrite',
  VaultWhitelistRead = 'VaultWhitelistRead',
  VaultWhitelistWrite = 'VaultWhitelistWrite',
  VaultDynamicDataRead = 'VaultDynamicDataRead',
  InternalAndExternalShopWrite = 'InternalAndExternalShopWrite',
  QuickPlayFactorsRead = 'QuickPlayFactorsRead',
  QuickPlayFactorsWrite = 'QuickPlayFactorsWrite'
}

export enum SystemActorType {
  GameClient = 'GameClient',
  GameServer = 'GameServer',
  LogViewerApp = 'LogViewerApp',
  RoutingEventsApp = 'RoutingEventsApp',
  RemoteFindLobbyApp = 'RemoteFindLobbyApp',
  RemoteMatchmakingApp = 'RemoteMatchmakingApp',
  MatchmakingApp = 'MatchmakingApp',
  SpawnerManagerApp = 'SpawnerManagerApp',
  ApiServer = 'ApiServer',
  Api = 'Api',
  Benchmark = 'Benchmark',
  DevTools = 'DevTools',
  UptimeMonitor = 'UptimeMonitor',
  VaultClient = 'VaultClient',
  Vault = 'Vault',
  DataProvider = 'DataProvider',
  DataManagementApp = 'DataManagementApp',
  DataManagementAppUI = 'DataManagementAppUI',
  None = 'None'
}

export abstract class WithUser {
  user: SystemUser
}
