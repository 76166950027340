import { UserRole } from "../actors/types";
import "reflect-metadata";

export function rolesGuard(requiredRoles: UserRole[]) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    Reflect.defineMetadata("roles", requiredRoles, target, propertyKey);
  };
}
