import { SystemActorType, UserRole } from './types'

export class SystemActor {
    readonly type: SystemActorType
  
    readonly roles: UserRole[]
  
    constructor( type: SystemActorType, roles: UserRole[] ) {
      this.type = type
      this.roles = roles
    }
  }