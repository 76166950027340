import { JsonObject } from 'type-fest'

export type ExceptionWithCauseArg = {
  cause: Error | { message: string }
}

export class ExceptionWithCause extends Error {
  readonly type: string = 'ExceptionWithCause'
  readonly cause: Error | JsonObject

  constructor( message: string, options: ExceptionWithCauseArg ) {
    super( message )
    this.cause = options.cause
  }

  toJSON() {
    return {
      type: this.type,
      message: this.message,
      callStack: this.stack,
      cause: {
        message: this.cause.message,
        callStack: this.cause.stack,
      },
    }
  }
}
