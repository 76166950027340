import { ClientError } from "./ClientError"
import { ClientMessage } from "./ClientMessage"

export interface ClientException {
  readonly clientMessage: ClientMessage
}

export const isClientError = (
  variableToCheck: any
): variableToCheck is ClientError =>
  ( variableToCheck as ClientError ).messageCode !== undefined &&
  ( variableToCheck as ClientError ).clientMessage !== undefined