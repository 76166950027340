import { Tag } from "./tags";

export type LoggableType = {
  readonly type: string
  readonly tags: readonly Tag[]
}

export const registered = new Set<LoggableType>()

/** Decorator */
export  function loggable( constructor: Function & LoggableType ) {
  constructor.prototype.tags  = ( constructor as any ).tags
  registered.add( constructor )
}

export interface LoggableErrorAsJson {
  readonly type: string
  readonly tags: string[]
}

export abstract class LoggableError extends Error {
  abstract readonly tags: readonly Tag[]

  constructor( message: string ) {
    super( message )
  }

  abstract toJSON(): LoggableErrorAsJson
}