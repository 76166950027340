import { LoggableError, Tag, loggable, tags } from "../../logger"

@loggable
export class PermissionDenied extends LoggableError {
  static readonly type = 'PermissionDenied'
  static readonly tags = Object.freeze([
    tags.Security
  ])

  readonly type: string
  readonly tags: readonly Tag[]

  constructor () {
    super( 'Permission denied' )
    this.tags = PermissionDenied.tags
  }

  toJSON() {
    return {
      type: this.type,
      tags: this.tags.map( it => it.id ),
      stack: this.stack,
    }
  }
}