import React, { useEffect, useState } from "react";
import "./App.css";
import { useRpc } from "./hooks/useRpc";
import { Spawner } from "./Components/SpawnerComponent/Spawner";
import Grid from "@mui/material/Grid";
import { Menu } from "./TopMenu/Menu";
import { Alert, Snackbar } from "@mui/material";
import { EditSpawnerConfigModal } from "./Components/EditSpawnerConfigModal/EditSpawnerConfigModal";
import { EnvironmentCard } from "./Components/EnvironmentCard/EnvironmentCard";
import { EditEnvModal } from "./Components/EditSMModal/EditSMModal";
import { Login } from "./Components/Login/Login";
import { Whitelist } from "./Components/Whitelist/Whitelist";
import { FactorsCard } from "./Components/FactorsCard/FactorsCard";
import { EditFactorsModal } from "./Components/FactorsModal/EditFactors";
import { QuickPlayFactors } from "shared";

type Severity = "error" | "info" | "success" | "warning";

type View = "spawners" | "whitelist";

function App() {
  const rpc = useRpc();

  const [spawnersDataInitialized, setSpawnersDataInitialized] = useState(false);

  const [view, setView] = useState<View>("spawners");

  const [spawnersConfigs, setSpawnersConfigs] = React.useState([]);
  const [onlineSpawners, setOnlineSpawners] = React.useState({});
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState<{
    message: string;
    severity: Severity;
  }>({ message: "", severity: "info" });
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [spawnerToEdit, setSpawnerToEdit] = React.useState(null);
  const [spawnerManagers, setSpawnerManagers] = React.useState([]);
  const [envToEdit, setEnvToEdit] = React.useState(null);
  const [envModalOpen, setEnvModalOpen] = React.useState(false);
  const [spawnerStates, setSpawnerStates] = useState<
    Record<
      string,
      { inVault: string; inSpawnerManager: string; spawnerState: string }
    >
  >({});
  const [spawnersByEnv, setSpawnersByEnv] = useState({});
  const [unassignedSpawners, setUnassignedSpawners] = useState([]);
  const [factors, setFactors] = useState({});
  const [factorsToEdit, setFactorsToEdit] = useState(
    {} as { env: string; data: QuickPlayFactors }
  );
  const [editFactorsModalOpen, setEditFactorsModalOpen] = useState(false);

  useEffect(() => {
    async function init() {
      if (!rpc.authenticated) return;

      const res = await rpc.call("spawners", "getStates", []);
      const configs = await rpc.call("spawners", "getAllConfigs", []);
      const spawnerManagersRes = await rpc.call(
        "spawnerManagers",
        "getAll",
        []
      );

      const factorsRes = await rpc.call(
        "factors",
        "getAllQuickPlayFactors",
        []
      );

      console.log("Fetched data", {
        spawners: res,
        configs,
        spawnerManagersRes,
        factorsRes,
      });

      const f = factorsRes.reduce((acc, factor) => {
        acc[factor.environment] = factor.factors;
        return acc;
      }, {});
      setFactors(f);

      const spawnersByEnvironment = spawnerManagersRes.reduce((acc, sm) => {
        acc[sm.environment] = configs.filter((s) =>
          sm.spawners.includes(s.name)
        );
        return acc;
      }, {});

      const assignedSpawners = Object.values(spawnersByEnvironment)
        .flat()
        .map((s: any) => s.name);
      const unassigned = configs.filter(
        (s) => !assignedSpawners.includes(s.name)
      );
      setUnassignedSpawners([...unassigned]);

      const states = configs.reduce((acc, curr) => {
        acc = {
          ...acc,
          [curr.name]: {
            inVault: "unknown",
            inSpawnerManager: "unknown",
            spawnerState: "unknown",
          },
        };
        return acc;
      }, {});

      setSpawnerStates({ ...states });

      configs.forEach((config) => {
        rpc
          .call("spawners", "getSpawnerLock", [{ spawnerName: config.name }])
          .then((state) => {
            const updated = { [config.name]: state };

            setSpawnerStates((spawnerStates) => ({
              ...spawnerStates,
              ...updated,
            }));
          })
          .catch((e) => {
            const updated = {
              [config.name]: {
                inVault: "unknown",
                inSpawnerManager: "unknown",
                spawnerState: "unknown",
              },
            };

            setSpawnerStates((spawnerStates) => ({
              ...spawnerStates,
              ...updated,
            }));
          });
      });

      setSpawnersConfigs([...configs]);
      setOnlineSpawners({ ...res });
      setSpawnerManagers([...spawnerManagersRes]);
      setSpawnersByEnv({ ...spawnersByEnvironment });
      setSpawnersDataInitialized(true);
    }

    init();
  }, [rpc.rpcClient, rpc.authenticated]);

  const stopSpawner = async (spawnerName: string) => {
    try {
      const state = await rpc.call("spawners", "stopSpawner", [
        { spawnerName },
      ]);
      openSnackbar(`Spawner ${spawnerName} stopped`, "success");
      onlineSpawners[spawnerName] = state;
    } catch (e) {
      openSnackbar(e, "error");
    }
  };

  const startSpawner = async (spawnerName: string) => {
    try {
      const state = await rpc.call("spawners", "startSpawner", [
        { spawnerName },
      ]);
      openSnackbar(`Spawner ${spawnerName} started`, "success");
      onlineSpawners[spawnerName] = state;
    } catch (err) {
      console.log({ err });
      openSnackbar(err, "error");
    }
  };

  const restartSpawner = async (spawnerName: string) => {
    try {
      const state = await rpc.call("spawners", "restartSpawner", [
        { spawnerName },
      ]);
      openSnackbar(`Spawner ${spawnerName} restarted`, "success");
      onlineSpawners[spawnerName] = state;
    } catch (err) {
      console.log({ err });
      openSnackbar(err, "error");
    }
  };

  const openEditSpawnerModal = (spawnerConfig: any) => {
    setEditModalOpen(true);
    setSpawnerToEdit({ ...spawnerConfig });
  };

  const openSnackbar = (message: string, severity: Severity) => {
    setSnackbar({ message, severity });
    setSnackbarOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleModalClose = () => {
    setSpawnerToEdit(null);
    setEditModalOpen(false);
  };

  const updateSpawnerConfig = async (config: any) => {
    try {
      await rpc.call("spawners", "updateSpawnerConfig", [
        { spawnerConfig: config },
      ]);
      openSnackbar(`Spawner ${config.name} updated`, "success");
      // onlineSpawners[spawnerName] = state;
    } catch (err) {
      console.log({ err });
      openSnackbar(err, "error");
    }
  };

  const deleteSpawner = async (spawnerName: string, version: string) => {
    try {
      await rpc.call("spawners", "removeSpawner", [{ spawnerName, version }]);
      openSnackbar(`Spawner ${spawnerName} deleted`, "success");
      delete onlineSpawners[spawnerName];
    } catch (err) {
      console.log({ err });
      openSnackbar(err, "error");
    }
  };

  const deleteEnv = async (env: string) => {};

  const updateEnv = async (env: any) => {
    try {
      await rpc.call("spawners", "updateSupportedSpawners", [
        {
          environment: env.environment,
          names: env.spawners,
          spawnersVersion: "1.0.1",
        },
      ]);

      await rpc.call("spawnerManagers", "updateSpawnerManagerConfig", [
        { environment: env.environment, config: env.config },
      ]);

      openSnackbar(`SpawnerManager ${env.environment} updated`, "success");
    } catch (err) {
      console.log({ err });
      openSnackbar(err, "error");
    }
  };

  const updateFactors = async (args: {
    environment: string;
    factors: QuickPlayFactors;
  }) => {
    try {
      console.log(args);
      await rpc.call("factors", "updateQuickPlayFactors", [args]);

      openSnackbar(`Factors for ${args.environment} updated`, "success");
    } catch (err) {
      console.log({ err });
      openSnackbar(err, "error");
    }
  };

  const editEnv = (env: any) => {
    setEnvToEdit(env);
    setEnvModalOpen(true);
  };

  const editFactors = (env: string) => {
    setFactorsToEdit({ env, data: factors[env] });
    setEditFactorsModalOpen(true);
  };

  const handleEnvModalClose = () => {
    setEnvToEdit(null);
    setEnvModalOpen(false);
  };

  const handleFactorsModalClose = () => {
    setFactorsToEdit(null);
    setEditFactorsModalOpen(false);
  };

  const handleMenuButton = (option: string) => {
    if (["spawners", "whitelist"].includes(option)) {
      setView(option as View);
    } else {
      if (option === "editSpawner") {
        setEditModalOpen(true);
      } else if (option === "editSpawnerManager") {
        setEnvModalOpen(true);
      }
    }
  };

  const setLock = async (locked: boolean, spawnerName: string) => {
    try {
      if (locked) {
        await rpc.call("spawners", "lockSpawner", [{ spawnerName }]);
      } else {
        await rpc.call("spawners", "unlockSpawner", [{ spawnerName }]);
      }

      rpc
        .call("spawners", "getSpawnerLock", [{ spawnerName }])
        .then((state) => {
          const updated = { [spawnerName]: state };

          setSpawnerStates((spawnerStates) => ({
            ...spawnerStates,
            ...updated,
          }));
        })
        .catch((e) => {
          const updated = {
            [spawnerName]: {
              inVault: "unknown",
              inSpawnerManager: "unknown",
              spawnerState: "unknown",
            },
          };

          setSpawnerStates((spawnerStates) => ({
            ...spawnerStates,
            ...updated,
          }));
        });

      openSnackbar(`Spawner ${locked ? "Locked" : "Unlocked"}`, "success");
    } catch (err) {
      console.log({ err });
      openSnackbar(err, "error");
    }
  };

  return (
    <>
      {!rpc.authenticated ? (
        <Login />
      ) : !spawnersDataInitialized ? (
        <></>
      ) : (
        <>
          <Menu
            pageName={view === "spawners" ? "Spawners" : "Whitelist"}
            menuButtonClick={handleMenuButton}
          />
          {view === "whitelist" ? (
            <Whitelist />
          ) : (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={8}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  {spawnerManagers.map((sm) => (
                    <Grid item sx={{ width: "100%" }}>
                      <EnvironmentCard
                        environment={sm}
                        edit={editEnv}
                        deleteEnv={deleteEnv}
                      >
                        <FactorsCard
                          factors={factors[sm.environment]}
                          env={sm.environment}
                          edit={(env: string) => editFactors(env)}
                        />
                        {spawnersByEnv[sm.environment].map((config: any) => (
                          <Spawner
                            spawner={config}
                            state={spawnerStates[config.name as string] as any}
                            runningState={
                              (onlineSpawners[config.name as string] &&
                                onlineSpawners[config.name as string] ===
                                  "running") ??
                              false
                            }
                            menuFunctions={{
                              start: startSpawner,
                              stop: stopSpawner,
                              restart: restartSpawner,
                              delete: deleteSpawner,
                              setLock,
                              edit: () => openEditSpawnerModal(config),
                            }}
                          />
                        ))}
                      </EnvironmentCard>
                    </Grid>
                  ))}
                  <Grid item sx={{ width: "100%" }}>
                    <EnvironmentCard
                      environment={{
                        environment: "Unassigned spawners",
                        spawners: [],
                        config: {},
                      }}
                      edit={null}
                      deleteEnv={null}
                    >
                      {unassignedSpawners.map((config: any) => (
                        <Spawner
                          spawner={config}
                          state={spawnerStates[config.name as string] as any}
                          runningState={
                            (onlineSpawners[config.name as string] &&
                              onlineSpawners[config.name as string] ===
                                "running") ??
                            false
                          }
                          menuFunctions={{
                            start: startSpawner,
                            stop: stopSpawner,
                            restart: restartSpawner,
                            delete: deleteSpawner,
                            setLock,
                            edit: () => openEditSpawnerModal(config),
                          }}
                        />
                      ))}
                    </EnvironmentCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
          <EditSpawnerConfigModal
            open={editModalOpen}
            handleClose={handleModalClose}
            spawner={spawnerToEdit}
            update={updateSpawnerConfig}
          />
          <EditEnvModal
            open={envModalOpen}
            handleClose={handleEnvModalClose}
            spawnerManager={envToEdit}
            spawnersName={spawnersConfigs.map((spawner) => spawner.name)}
            spawnersInUse={spawnerManagers.map((sm) => sm.spawners).flat()}
            update={updateEnv}
          />
          <EditFactorsModal
            open={editFactorsModalOpen}
            factors={factorsToEdit}
            handleClose={() => handleFactorsModalClose()}
            update={(args: {
              environment: string;
              factors: QuickPlayFactors;
            }) => updateFactors(args)}
          />
        </>
      )}
    </>
  );
}

export default App;
