import { useEffect, useRef, useState } from 'react'
import { useRpc } from '../../hooks/useRpc'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'

function not(a: string[], b: string[]) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a: string[], b: string[]) {
  return a.filter(value =>  b.indexOf(value) !== -1)
}

export const Whitelist = () => {
  const rpc = useRpc()

  const [authLists, setAuthLists] = useState(null)

  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const idsInput = useRef(null)

  useEffect(() => {
    async function init() {
      if(!rpc.authenticated) return

      const lists = await rpc.call("whitelist", "get", []);
      setAuthLists(lists)
      setLeft(lists.whitelist)
    }

    init();
  }, [rpc.rpcClient, rpc.authenticated]);

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleToggle = (value: string) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if( currentIndex === -1 ) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const customList = (items: readonly string[]) => (
    <Paper sx={{ width: "100%", height: 500, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value: string) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={() => handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return <Grid container spacing={2} justifyContent="center" alignItems="center" >
    <Grid item>{customList(left)}</Grid>
    <Grid item>
      <Grid container direction="column" alignItems="center">
      <Button
        sx={{ my: 0.5 }}
        variant="outlined"
        size="small"
        onClick={handleAllRight}
        disabled={left.length === 0}
        aria-label="move all right"
      >
        ≫
      </Button>
      <Button
        sx={{ my: 0.5 }}
        variant="outlined"
        size="small"
        onClick={handleCheckedRight}
        disabled={leftChecked.length === 0}
        aria-label="move selected right"
      >
        &gt;
      </Button>
      <Button
        sx={{ my: 0.5 }}
        variant="outlined"
        size="small"
        onClick={handleCheckedLeft}
        disabled={rightChecked.length === 0}
        aria-label="move selected left"
      >
        &lt;
      </Button>
      <Button
        sx={{ my: 0.5 }}
        variant="outlined"
        size="small"
        onClick={handleAllLeft}
        disabled={right.length === 0}
        aria-label="move all left"
      >
        ≪
      </Button>
      </Grid>
    </Grid>
    <Grid item>{customList(right)}</Grid>
    <Grid container marginTop={2} spacing={2} justifyContent="center" alignItems="center">
      <Button onClick={async () => {
        try {
          await rpc.call('whitelist', 'removeFromWhitelist', [{ids: right}])
          setRight([])
          setAuthLists(left)
        } catch ( e ) {
          console.log(e)
      }}}>Save</Button>
      <Grid container marginTop={2} spacing={2} justifyContent="center" alignItems="center">
        <TextField type="text" inputRef={idsInput} />
        <Button onClick={async () => {
          const ids = idsInput.current.value.replace(/\s/g, "").split(',')

          try {
            await rpc.call('whitelist', 'addToWhitelist', [{ids}])
            setAuthLists(authLists.whitelist.concat(ids))
            setLeft(left.concat(ids))
          } catch ( e ) {
            console.log(e)
          }
        }}>Add</Button>
      </Grid>
    </Grid>
  </Grid>
}