import { useRef } from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { useRpc } from '../../hooks/useRpc'
import Button from '@mui/material/Button'

export const Login = () => {
  const rpc = useRpc()

  const user = useRef(null)
  const passwd = useRef(null)

  return <>
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "25ch" },
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
      //   noValidate
      autoComplete="off"
    >
      <TextField
        id="outlined-basic"
        label="Login"
        variant="outlined"
        inputRef={user}
      />
      <TextField
        type="password"
        id="outlined-basic"
        label="Password"
        variant="outlined"
        inputRef={passwd}
      />
      <Button onClick={() => {
        rpc.authenticate(user.current.value, passwd.current.value)
      }}>LogIn</Button>
    </Box>
  </>
}