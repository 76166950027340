// TODO replace with real error logging
const logError = ( e: any ) => console.error( e )

/**
 * @param fn
 * @param errorHandler
 */
export function catchAsyncErrors<Args extends unknown[], R>( fn: ( ...args: Args ) => Promise<R>, errorHandler = logError ): ( ...args: Args ) => void {
  return function ( ...args: Args ) {
    fn( ...args ).catch( errorHandler )
  }
}
