import { Loggable, errors, info, warnings } from "../events"
import { GenericError } from "./GenericError"

export const logger = {
  error<U extends Loggable>( errorOrMessage: U | string, ...data: unknown[] ) {
    if (typeof errorOrMessage === 'string' ) {
      errors.push( new GenericError( errorOrMessage, data)  )
    }
    else {
      errors.push( errorOrMessage )
    }
  },
  warn: warnings.push,
  info: info.push,
  log( ...args: unknown[] ) { 
    info.push(
        { 
            type: 'Logged data',
            ...args
        }  
    )  
  }  
} 