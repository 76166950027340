import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import EditIcon from "@mui/icons-material/Edit";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export const Options = ({
  online,
  menuFunctions,
  spawnerName,
  version,
  isLocked
}: {
  online: boolean;
  spawnerName: string;
  version: string;
  isLocked: boolean
  menuFunctions: {
    start: (spawnerName: string) => Promise<void>;
    stop: (spawnerName: string) => Promise<void>;
    restart: (spawnerName: string) => Promise<void>;
    delete: (spawnerName: string, version: string) => Promise<void>;
    setLock: (locked: boolean, spawnerName: string, spawnerVersion: string) => Promise<void>
    edit: () => void;
  };
}) => {
  return (
    <BottomNavigation showLabels>
      {online && (
        <BottomNavigationAction
          label="Stop"
          icon={<PauseIcon />}
          onClick={() => menuFunctions.stop(spawnerName)}
        />
      )}
      {online && (
        <BottomNavigationAction
          label="Restart"
          icon={<RestartAltIcon />}
          onClick={() => menuFunctions.restart(spawnerName)}
        />
      )}
      {!online && (
        <BottomNavigationAction
          label="Start"
          icon={<PlayArrowIcon />}
          onClick={() => menuFunctions.start(spawnerName)}
        />
      )}

      <BottomNavigationAction
        label="Edit"
        icon={<EditIcon />}
        onClick={() => menuFunctions.edit()}
      />
      <BottomNavigationAction
        label="Delete"
        icon={<DeleteIcon />}
        onClick={() => menuFunctions.delete(spawnerName, version)}
      />
      <BottomNavigationAction disabled label="Update" icon={<UpgradeIcon />} />
      <BottomNavigationAction label={isLocked ? "Unlock" : "Lock"} icon={isLocked ? <LockOpenIcon /> : <LockIcon />} onClick={() => menuFunctions.setLock(!isLocked, spawnerName, version)} />
    </BottomNavigation>
  );
};
